import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Badge, Card } from 'react-bootstrap';

import { FIELDS, TForm } from 'src/components/Forms/form.interface';
import DynamicForm from 'src/components/Forms/DynamicForm';
import CustomerStore, { GetBonusesAccountResponseDto, PersonDto } from 'src/store/CustomerStore';
import { formatPhone, parseBonuses } from 'src/utils/formatters';
import { BadgeLink } from 'src/components/BadgeLink';
import { PlatformCRMWeb } from 'src/services/GrpcService';
import ProspectStore from 'src/store/ProspectStore';

interface IProps {
  person: PersonDto;
}

const CustomerInfo = observer((props: IProps) => {
  const { person } = props;
  const { updateCustomer, customer } = CustomerStore;
  const { prospect } = ProspectStore;
  const [bonusesAccount, setBonusesAccount] = useState<GetBonusesAccountResponseDto>();
  const [form, setForm] = useState<TForm>({ name: 'person-edit', rows: [] });

  useEffect(() => {
    (async function getBonusesAccount() {
      const { getBonusesAccount } = CustomerStore;
      const result = await getBonusesAccount({
        userId: person.ssoId,
        ticker: PlatformCRMWeb.AccountTicker.BONUS,
      });
      setBonusesAccount(result);
    })();
  }, [person.ssoId]);

  useEffect(() => {
    const bonuses = bonusesAccount?.total || 0;
    const dynamicForm = {
      name: 'person-edit',
      rows: [
        [
          {
            type: FIELDS.FIO,
            name: 'fio',
            label: {
              label: 'ФИО',
            },
            initialValue: person.fio,
            isReadonly: false,
          },
        ],
        [
          {
            type: FIELDS.MOBILE_PHONE,
            name: 'phone',
            label: {
              label: 'Телефон',
            },
            initialValue: formatPhone(person.phone, true),
            isReadonly: Boolean(customer?.ssoId || person.ssoId),
            href: `tel:${person.phone}`,
          },
        ],
        [
          {
            type: FIELDS.STRING,
            name: 'leadCode',
            label: {
              label: 'Код лида',
            },
            initialValue: prospect?.phone,
            isReadonly: true,
            hasCopyButton: true,
          },
        ],
        [
          {
            type: FIELDS.BADGE,
            name: 'bonuses',
            label: {
              label: 'Бонусы',
            },
            initialValue: parseBonuses(bonuses),
            variant: 'primary',
          },
        ],
        [
          {
            type: FIELDS.STRING,
            name: 'email',
            label: {
              label: 'Эл. почта',
            },
            initialValue: person.email,
          },
        ],
        [
          {
            type: FIELDS.STRING,
            name: 'commentForSupport',
            label: {
              label: 'Комментарий для поддержки',
            },
            initialValue: person.commentForSupport,
            variant: 'textarea',
          },
        ],
        [
          {
            type: FIELDS.STRING,
            name: 'commentForExecutor',
            label: {
              label: 'Комментарий для исполнителя',
            },
            initialValue: person.commentForExecutor,
            variant: 'textarea',
          },
        ],
      ],
    };

    setForm(dynamicForm);
  }, [person, bonusesAccount, customer]);

  const onClickSubmit = (values) => {
    const { fio, createdAt, updatedAt, ...rest } = values;
    const newPerson = { ...person, ...rest, fio };

    updateCustomer({ person: newPerson, prospect });
  };

  return (
    <Card className="mb-4">
      <Card.Header className="d-flex justify-content-between" as="h6">
        <span>Клиент</span>
        <div className="d-flex row">
          <BadgeLink
            className="card-header badge-outline-primary border-bottom-0"
            title="Профиль"
            link={person.qleanAdminLink}
            onClick={(link) => window.open(link, '_blank')}
          />
          {Boolean(person.containsLabel) && (
            <Badge className="ml-2" variant="danger">Новая швабра</Badge>
          )}
        </div>
      </Card.Header>
      <Card.Body>
        <DynamicForm form={form} onSubmit={onClickSubmit} />
      </Card.Body>
    </Card>
  );
});

export default CustomerInfo;
