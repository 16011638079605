import { PlatformCRMWeb } from 'src/services/GrpcService';

const callbackDate = (addMinutes: number) => {
  return () => {
    const now = new Date();
    return new Date(now.setMinutes(now.getMinutes() + addMinutes));
  };
};

export interface IPaymentType {
  id: string;
  value: string;
  button?: string;
  default?: boolean;
  meta?: string; // id карты для paymentTypeMeta в проспект/заказ
}

export interface INumberOfPayments {
  id: string;
  value: number;
}

export const labsServiceTypes = [
  'cleaning_flat_deep',
  'cleaning_flat_renovation',
  'cleaning_house',
  'plt_drycleaning',
  'plt_drycleaning_shoes',
  'plt_drycleaning_and_restoration_shoes',
  'drycleaning_furniture',
  'plt_drycleaning_carpet',
  'plt_drycleaning_curtains',
  'plt_drycleaning_stroller',
  'ya_drycleaning_furniture',
  'cleaning_house_deep',
  'hourly_deep_cleaning',
  'cleaning_flat_combined',
  'cleaning_air_conditioner',
  'sell_goods_chemistry',
  'sell_goods',
  'sell_goods_new',
  'cleaning_grout_epoxy',
  'cleaning_mold',
  'cleaning_sealant',
];

const paymentType: IPaymentType = { id: 'cash', value: 'Наличные', button: 'Привязать по смс' };
const paymentTypeForLabs: IPaymentType = { id: 'sms', value: 'По смс' };

const paymentTypesByServiceType: Record<string, IPaymentType[]> = {
  plt_drycleaning_carpet: [{ id: 'soft_pos', value: 'Оплата по терминалу' }],
  plt_drycleaning_stroller: [{ id: 'soft_pos', value: 'Оплата по терминалу' }],
  plt_drycleaning_curtains: [{ id: 'soft_pos', value: 'Оплата по терминалу' }],
  plt_drycleaning: [{ id: 'soft_pos', value: 'Оплата по терминалу' }],
  plt_drycleaning_shoes: [{ id: 'soft_pos', value: 'Оплата по терминалу' }],
  plt_drycleaning_and_restoration_shoes: [{ id: 'soft_pos', value: 'Оплата по терминалу' }],
  plt_drycleaning_leather: [{ id: 'soft_pos', value: 'Оплата по терминалу' }],
  plt_laundry_clothes: [{ id: 'soft_pos', value: 'Оплата по терминалу' }],
  sell_goods_chemistry: [
    { id: 'sms', value: 'С основной карты' },
    { id: 'soft_pos', value: 'Оплата по QR коду' },
  ],
};

const numberOfPayments: INumberOfPayments[] = [
  { id: '2', value: 2 },
  { id: '4', value: 4 },
];

export const dictionary = {
  formControl: {
    id: 'ID',
    type: 'Тип',
    createdDate: 'Дата создания',
    postponedUntil: 'Дата перезвона',
    missCallsCount: 'Кол-во перезвонов',
    skill: 'Скилл',
    usp: 'Скидка',
    taskNumber: 'Номер задачи',
    createdAt: 'Дата/время создания',
    updatedAt: 'Дата последнего обновления',
    owner: 'Владелец',
    prospectNumber: 'Номер заявки',
    strategy: 'Тип',
    serviceType: 'Продукт',
    status: 'Статус',
    cancelReason: 'Причина отказа',
    orderNumber: 'Номер заказа',
    startDate: 'Дата/время',
    address: 'Адрес',
    options: 'Опции',
    totalPrice: 'Цена',
    promocode: 'Промокод',
    manager: 'Кем оформлен',
    commentForExecutor: 'Комментарий для клинера',
    commentForSupport: 'Комментарий для поддержки',
    commentFromCustomer: 'Комментарий клиента',
    review: 'Отзыв',
    score: 'Оценка',
    fio: 'ФИО',
    phone: 'Телефон',
    email: 'Эл. почта',
    totalDuration: 'Время',
    minPartPrice: 'Минимальная часть оплаты заказа',
  },
  checkControl: {
    managerState: {
      0: 'Не определён',
      1: 'Онлайн',
      2: 'Оффлайн',
    },
  },
  selectOptions: {
    taskStatus: [
      { id: PlatformCRMWeb.TaskState.NEW, value: 'Новая' },
      { id: PlatformCRMWeb.TaskState.IN_WORK, value: 'В работе' },
      { id: PlatformCRMWeb.TaskState.PROCEED, value: 'Успешно' },
      { id: PlatformCRMWeb.TaskState.CANCELLED, value: 'Отменено' },
    ],
    communicationStatus: [
      // { id: PlatformCRMWeb.CommunicationState.FREE, value: 'Свободна' },
      { id: PlatformCRMWeb.CommunicationState.ASSIGNED, value: 'В работе' },
      // { id: PlatformCRMWeb.CommunicationState.PROCESSED, value: 'Обработана' },
      { id: PlatformCRMWeb.CommunicationState.POSTPONED, value: 'Отложена' },
      { id: PlatformCRMWeb.CommunicationState.NO_COMMUNICATION, value: 'Нет коммуникаций' },
      { id: PlatformCRMWeb.CommunicationState.CLOSED, value: 'Завершена' },
      { id: PlatformCRMWeb.CommunicationState.DECLINED, value: 'Отменена' },
      { id: PlatformCRMWeb.CommunicationState.NEGATIVE_CANCELLATION, value: 'Негатив' },
      { id: PlatformCRMWeb.CommunicationState.RESCHEDULED, value: 'Отложена' },
    ],
    prospectStatus: [
      { id: 'new', value: 'Новая' },
      { id: 'ready', value: 'Готова к обработке' },
      { id: 'queued', value: 'В очереди' },
      { id: 'in_work', value: 'В работе' },
      { id: 'success', value: 'Успешно завершена' },
      { id: 'cancel', value: 'Завершена с отказом' },
      { id: 'recall', value: 'Перезвон' },
    ],
    orderStatus: [
      { id: 'new', value: 'Новый', type: 'primary' },
      { id: 'review', value: 'На проверке', type: 'info' },
      { id: 'cancel', value: 'Отказ', type: 'danger' },
      { id: 'created', value: 'Создан', type: 'warning' },
      { id: 'confirmed', value: 'Подтвержден', type: 'warning' },
      { id: 'assigned', value: 'Назначен', type: 'secondary' },
      { id: 'started', value: 'Начат', type: 'secondary' },
      { id: 'finished', value: 'Закончен', type: 'dark' },
      { id: 'paid', value: 'Оплачен', type: 'success' },
    ],
    region: [
      { id: 1, value: 'Москва' },
      { id: 2, value: 'Санкт-Петербург' },
    ],
    regularity: [
      { id: 'single', value: 'Разовый заказ' },
      { id: '16', value: 'Раз в месяц' },
      { id: '2', value: 'Раз в 2 недели' },
      { id: '3', value: 'Раз в неделю' },
    ],
    discounts: [{ id: 0, value: '0 %', promocode: '' }],
    paymentType,
    numberOfPayments,
    missedCallReasons: [
      { id: 1, value: 'Номер не существует' },
      { id: 2, value: 'Телефон выключен' },
      { id: 3, value: 'Не взял трубку' },
      { id: 4, value: 'Бросил трубку' },
      { id: 5, value: 'Занято' },
    ],
    communicationCancellationReasons: [
      { id: PlatformCRMWeb.CancellationReason.NUMBER_NOT_EXISTS, value: 'Номер не существует' },
      { id: PlatformCRMWeb.CancellationReason.UNAVAILABLE, value: 'Номер не доступен' },
      { id: PlatformCRMWeb.CancellationReason.NO_PICKUP, value: 'Клиент не ответил' },
      { id: PlatformCRMWeb.CancellationReason.BUSY, value: 'Линия занята' },
    ],
    orderCancelReasons: [
      { id: 1, value: 'Не выезжаем в локацию' },
      { id: 2, value: 'Еще идет ремонт' },
      { id: 3, value: 'Заказали у конкурентов' },
      { id: 4, value: 'Дорого' },
      { id: 5, value: 'Изменились планы, не знают даты переноса' },
      { id: 6, value: 'Не удалось дозвониться для подтверждения заказа' },
      {
        id: 7,
        value: 'Отменен по прибытии команды',
        options: [
          { id: 71, value: 'Не сошлись по времени' },
          { id: 72, value: 'Конфликтная ситуация' },
          { id: 73, value: 'Клиент передумал' },
          { id: 74, value: 'Не хватает клинеров' },
          { id: 75, value: 'Не смогли встретить' },
          { id: 76, value: 'Нет воды / электричества' },
        ],
      },
      { id: 8, value: 'Ошибка оформления заказа' },
      { id: 9, value: 'Уезжает' },
      { id: 10, value: 'Иной тип уборки' },
      { id: 11, value: 'Команда не успевает' },
      { id: 12, value: 'Нет свободных слотов для переноса' },
      {
        id: 13,
        value: 'Не устраивают условия',
        options: [
          { id: 131, value: 'Не принимаем наличные' },
          { id: 132, value: 'Нет терминала' },
        ],
      },
      {
        id: 15,
        value: 'Убрались сами',
      },
      {
        id: 16,
        value: 'Отказ (негатив)',
      },
      {
        id: 17,
        value: 'Заболели',
      },
    ],
    callbackPresets: [
      {
        id: 1,
        value: 'Через 5 минут',
        getDate: callbackDate(5),
      },
      {
        id: 2,
        value: 'Через 30 минут',
        getDate: callbackDate(30),
      },
      {
        id: 3,
        value: 'Через 2 часа',
        getDate: callbackDate(120),
      },
      {
        id: 4,
        value: 'Через 4 часа',
        getDate: callbackDate(240),
      },
      {
        id: 5,
        value: 'На следующий день в это время',
        getDate: callbackDate(1440),
      },
      { id: 6, value: 'Свой вариант' },
    ],
    cleaners: [
      { id: 1, value: 'Клинер 1' },
      { id: 2, value: 'Клинер 2' },
    ],
    executorRoles: [
      { id: 0, plural: 'Клинеры - главные', singular: 'Клинер - главный' },
      { id: 3, plural: 'Клинеры - старшие', singular: 'Клинер - старший' },
      { id: 4, plural: 'Клинеры - наставники', singular: 'Клинер - наставник' },
      { id: 1, plural: 'Клинеры - линейные', singular: 'Клинер - линейный' },
      { id: 2, plural: 'Курьеры', singular: 'Курьер' },
    ],
    serviceTypes: [
      { id: 'cleaning_flat_standard', value: 'Поддерживающая уборка квартиры' },
      { id: 'cleaning_flat_deep', value: 'Генеральная уборка квартиры' },
      { id: 'hourly_deep_cleaning', value: 'Почасовая генеральная уборка' },
      { id: 'subscription', value: 'Подписка' },
      { id: 'cleaning_flat_renovation', value: 'Уборка квартиры после ремонта' },
      { id: 'cleaning_office_standard', value: 'Поддерживающая уборка офиса' },
      { id: 'cleaning_flat_windows', value: 'Мытьё окон в квартире' },
      { id: 'cleaning_flat_hourly', value: 'Почасовая уборка квартиры' },
      { id: 'cleaning_house_deep', value: 'Уборка загородного дома' },
      { id: 'plt_drycleaning', value: 'Химчистка' },
      { id: 'plt_drycleaning_curtains', value: 'Химчистка штор' },
      { id: 'drycleaning_furniture', value: 'Химчистка мебели' },
      { id: 'plt_drycleaning_carpet', value: 'Химчистка ковров' },
      { id: 'plt_drycleaning_stroller', value: 'Химчистка колясок' },
      { id: 'plt_laundry_clothes', value: 'Стирка и глажка' },
      { id: 'plt_drycleaning_leather', value: 'Химчистка кожи' },
      { id: 'plt_drycleaning_and_restoration_shoes', value: 'Химчистка и реставрация обуви' },
      { id: 'plt_drycleaning_shoes', value: 'Химчистка обуви' },
      { id: 'ya_drycleaning_furniture', value: 'Яндекс.Химчистка мебели' },
      { id: 'water', value: 'Вода' },
      { id: 'cleaning_flat_combined', value: 'Комплексная уборка' },
      { id: 'cleaning_air_conditioner', value: 'Чистка кондиционеров' },
      { id: 'sell_goods_chemistry', value: 'Продажа инвентаря и расходников' },
      { id: 'sell_goods_new', value: 'Продажа товаров' },
      { id: 'cleaning_grout_epoxy', value: 'Удаление эпоксидной затирки' },
      { id: 'cleaning_mold', value: 'Удаление плесени' },
      { id: 'cleaning_sealant', value: 'Замена герметика' },
    ],
    serviceTypesMenu: [
      {
        id: 'cleaning',
        value: 'Уборка',
        options: [
          // {
          //   id: 'cleaning_flat',
          //   value: 'Квартиры',
          //   options: [

          //   ],
          // },
          { id: 'cleaning_flat_standard', value: 'Поддерживающая уборка квартиры' },
          { id: 'cleaning_flat_deep', value: 'Генеральная уборка квартиры' },
          { id: 'hourly_deep_cleaning', value: 'Почасовая генеральная уборка' },
          { id: 'cleaning_flat_renovation', value: 'Уборка квартиры после ремонта' },
          { id: 'cleaning_flat_windows', value: 'Мытьё окон в квартире' },
          { id: 'cleaning_flat_hourly', value: 'Почасовая уборка квартиры' },
          { id: 'cleaning_house_deep', value: 'Уборка загородного дома' },
          { id: 'cleaning_flat_combined', value: 'Комплексная уборка' },
          { id: 'cleaning_office_standard', value: 'Поддерживающая уборка офиса' },
          // {
          //   id: 'cleaning_office',
          //   value: 'Офиса',
          //   options: [
          //     { id: 'cleaning_office_standard', value: 'Поддерживающая уборка офиса' },
          //     { id: 'cleaning_office_deep', value: 'Генеральная уборка офиса' },
          //     { id: 'cleaning_office_daytime', value: 'Регулярная уборка офиса' },
          //     { id: 'cleaning_office_windows', value: 'Мытье окон в офисе' },
          //   ],
          // },
          // { id: 'cleaning_job', value: 'Вакансия клинера' },
        ],
      },
      { id: 'plt_drycleaning', value: 'Химчистка' },
      { id: 'plt_drycleaning_curtains', value: 'Химчистка штор' },
      { id: 'drycleaning_furniture', value: 'Химчистка мебели' },
      { id: 'plt_drycleaning_carpet', value: 'Химчистка ковров' },
      { id: 'plt_drycleaning_stroller', value: 'Химчистка колясок' },
      { id: 'plt_laundry_clothes', value: 'Стирка и глажка' },
      { id: 'plt_drycleaning_leather', value: 'Химчистка кожи' },
      { id: 'plt_drycleaning_and_restoration_shoes', value: 'Химчистка и реставрация обуви' },
      { id: 'plt_drycleaning_shoes', value: 'Химчистка обуви' },
      { id: 'water', value: 'Вода' },
      { id: 'subscription', value: 'Подписка' },
      { id: 'cleaning_air_conditioner', value: 'Чистка кондиционеров' },
      { id: 'sell_goods_chemistry', value: 'Продажа инвентаря и расходников' },
      { id: 'sell_goods_new', value: 'Продажа товаров' },
      { id: 'cleaning_grout_epoxy', value: 'Удаление эпоксидной затирки' },
      { id: 'cleaning_mold', value: 'Удаление плесени' },
      { id: 'cleaning_sealant', value: 'Замена герметика' },
    ],
  },
  groupList: {},
  isRequired: 'Обязательное поле',
};

export const calculatorCardsSort = {
  drycleaning: {
    'Популярное и сезонное': 1,
    Аксессуары: 2,
    'Верхняя одежда': 3,
    'Деловая одежда': 4,
    'Повседневная одежда': 5,
    'Свадебная одежда': 6,
    'Нижнее белье': 7,
    Обувь: 8,
    'Детская одежда': 9,
    'Домашний текстиль': 10,
    Шторы: 11,
    Ремонт: 12,
    Стирка: 13,
    Другое: 14,
  },
  cleaning_flat_standard: {
    База: 1,
    Кухня: 2,
    'Ванная и туалет': 3,
    'Вся квартира': 4,
  },
};

export type TFormControl = keyof typeof dictionary['formControl'];
export type TCheckControl = keyof typeof dictionary['checkControl'];
export type TSelectOptions = keyof typeof dictionary['selectOptions'];
export type TGroupList = keyof typeof dictionary['groupList'];

export const lmsServiceTypes = [
  'sell_goods_new',
  'sell_goods_chemistry',
  'plt_drycleaning',
  'plt_drycleaning_carpet',
  'plt_drycleaning_stroller',
  'plt_drycleaning_curtains',
  'plt_drycleaning_leather',
  'plt_drycleaning_shoes',
  'plt_drycleaning_and_restoration_shoes',
  'plt_laundry_clothes',
];

export const serviceTypesWithoutRegularity = ['cleaning_flat_windows', 'cleaning_flat_deep', 'sell_goods', 'sell_goods_new', 'sell_goods_chemistry'];
export const serviceTypesWithContractorPaymentTypes = ['sell_goods_chemistry'];
export const externalCrms = ['water', 'laundry'];
export const B2BServices = ['sell_goods_chemistry'];

export function getTimeslotsSource(args: { paymentType: string; serviceType: string }) {
  // Убрать условие и добавить 'sell_goods_chemistry' в lmsServiceTypes, когда все кейсы b2b товарки будут в OMS
  if (args.serviceType === 'sell_goods_chemistry') {
    return args.paymentType === 'sms' ? null : 'LMS';
  }

  if (lmsServiceTypes.includes(args.serviceType)) {
    return 'LMS';
  }

  if (!labsServiceTypes.includes(args.serviceType)) {
    return 'CRM';
  }

  return null;
}

export function getDrycleaningPlan(service: string) {
  let BASIC_PLAN = '19179967-c670-40ab-91b9-cf247487a848';

  if (service === 'plt_drycleaning') {
    BASIC_PLAN = '41d7e10a-900c-4469-9e9f-37afbe155e30';
  }

  if (service === 'plt_drycleaning_shoes') {
    BASIC_PLAN = '6791e751-ee17-47e3-bfe7-cb391227e258';
  }

  // Мапинг промокода на план
  return {
    basic: BASIC_PLAN,
    drycleaning_outerwear_discount_season: '3f6e2390-281a-419c-8720-9aa9bc216860',
    // Химчистка + стирка 3 рубашек
    drycleaning_three_shirts: 'c0a36804-3008-4281-9453-70943305a449',
    // Скидка 20% на верхнюю одежду
    drycleaning_outerwear_discount: 'a4259f8d-aa7d-4a3b-9059-d9def907ca24',
    // Химчистка костюма
    drycleaning_suit_discount: '1184cdfa-a51e-4493-9da3-1a77eb37b0b3',
    OUTERWEARAGENTDRY15M: '5336a8ee-1520-4223-848d-00ff98f5d5d4',
    // проценты
    RCT5: BASIC_PLAN,
    RCT10: BASIC_PLAN,
    ABND15: BASIC_PLAN,
    CFO20: BASIC_PLAN,
    CFO30: BASIC_PLAN,
    ABND40: BASIC_PLAN,
    INCOMING20: BASIC_PLAN,
    drycleaning_curtains_plan: '873c7a97-dc65-4479-ad0d-54ecc6534324',
    laundry_ondemand: '41d7e10a-900c-4469-9e9f-37afbe155e30',
    DRCLCRTS10: '19179967-c670-40ab-91b9-cf247487a848',
    DRCLCRTS15: '19179967-c670-40ab-91b9-cf247487a848',
    DRCLCRTS20: '19179967-c670-40ab-91b9-cf247487a848',
    DRCLST20: 'a509c86e-553a-4285-9de4-8dea43ed1f8d',
    DRCLSHS20: '9f343fca-5616-419c-b86f-dbd8715dd784',
  };
}

export function isLegacyDrycleaning(service: string): boolean {
  return ['drycleaning', 'drycleaning_shoes', 'laundry_ondemand'].includes(service);
}

export function isSubscription(service: string): boolean {
  return ['subscription'].includes(service);
}

export function getPaymentType(service: string): IPaymentType {
  if (labsServiceTypes.includes(service)) {
    if (service === 'sell_goods_chemistry') {
      return { id: 'sms', value: 'С основной карты' };
    }

    return paymentTypeForLabs;
  }

  return { id: 'cash', value: 'Наличные', button: 'Привязать по смс' };
}

export function getPaymentTypesForService(service: string): IPaymentType[] | null {
  if (paymentTypesByServiceType[service]) {
    return [...paymentTypesByServiceType[service]];
  }

  return null;
}

export enum OrderStatus {
  New = 'new',
  Review = 'review',
  Cancel = 'cancel',
  Created = 'created',
  Confirmed = 'confirmed',
  Assigned = 'assigned',
  Started = 'started',
  Finished = 'finished',
  Paid = 'paid',
}

export enum OrderPaymentType {
  Cart = 'credit_card',
  Cash = 'cash',
  Sms = 'sms',
  SoftPos = 'soft_pos',
}

export enum ServiceType {
  SellGoodsNew = 'sell_goods_new',
}
